<template>
  <div class="cardUser">
    <div class="cardUser-content">
      <div class="btnClose" @click="sendEvent()">
        <CIcon name="cil-x" height="25" />
      </div>

      <div class="cardUser-title">{{ title }}</div>
      <hr />

      <div v-if="notification">
        <CAlert :color="notificationType">
          {{ notification }}
        </CAlert>
      </div>

      <div
        class="cardUser-subtitle btn-plus"
        @click="collapseBottom = !collapseBottom"
      >
        {{$t('user_details')}}
        <CIcon name="cil-plus" />
      </div>

      <CCollapse :show="showCollapseBottom == collapseBottom">
        <BlockInfo
          :title="$t('email') + ':'"
          v-model="_user.email"
          :disabled="btnEventText == 'New' ? false : true"
        />
        <BlockInfoSelect
          :title="$t('rol') + ':'"
          v-model="_user.rol"
          :selected="_user.rol"
          :values="rolValues"
          :disabled="inputsDisabled"
          @change-value="changeRole"
        />
        <BlockInfo
        :title="$t('user_name') + ':'"
          v-model="_user.username"
          :disabled="inputsDisabled"
        />
        <BlockInfoSelect
          :title="$t('country') + ':'"
          v-model="_user.country"
          :selected="_user.country"
          :values="countries"
          :disabled="inputsDisabled"
          v-if="!isSuperAdmin"
        />

        <!-- plantList -->
        <BlockColumn
          :title="$t('plants') + ':'"
          :eventName="!inputsDisabled ? 'add-plant-list' : null"
          @add-plant-list="addPlantList"
          v-if="!isSuperAdmin && !isCountryAdmin && !isShipToManager"
        >
          <BlockItem v-for="item in plantList" :key="item.key" :size="'1-3'">
            <BlockInfo
              :disabled="inputsDisabled"
              v-model="item.value"
              :buttonDelete="!inputsDisabled"
              :_key="item.key"
              @block-info-remove="removePlantList"
              maxlength="4"
            />
          </BlockItem>
        </BlockColumn>

        <div class="cardUser-sectionButtons">
          <CButton
            color="white"
            size="lg"
            class="cardUser-button"
            @click="sendEvent()"
            >{{$t('cancel')}}</CButton
          >
          <CButton
            :color="btnEventType"
            size="lg"
            class="cardUser-button"
            @click="actionEvent()"
            >{{ btnEventText }}</CButton
          >
        </div>
      </CCollapse>
    </div>
    <div class="cardUserShadow" @click="sendEvent()"></div>
  </div>
</template>

<script>
import BlockInfo from "./BlockInfo";
import BlockColumn from "./BlockColumn";
import BlockItem from "./BlockItem";
import BlockInfoSelect from "./BlockInfoSelect";

export default {
  name: "CardUser",
  components: {
    BlockInfo,
    BlockColumn,
    BlockItem,
    BlockInfoSelect,
  },
  props: {
    user: Object,
    title: String,
    notification: String,
    notificationType: String,
    btnEventText: String,
    btnEventType: String,
    inputsDisabled: Boolean,
    countryCodes: Array,
    showCollapseBottom: Boolean,
  },
  computed: {
    _user() {
      return this.user;
    },
    countries() {
      return this.countryCodes;
    },
    rolValues() {
      var rol = JSON.parse(localStorage.getItem("userRol"));
      if (rol == "superadmin") {
        return ["superadmin", "countryAdmin", "plantManager", "shipToManager"];
      } else if (rol == "countryAdmin") {
        return ["countryAdmin", "plantManager", "shipToManager"];
      }
    },
  },
  data() {
    return {
      isSuperAdmin: Boolean,
      isCountryAdmin: Boolean,
      collapseBottom: true,
      plantList: [],
    };
  },
  created() {
    const self = this;

    if (self._user.rol.toUpperCase() == "SUPERADMIN") self.isSuperAdmin = true;
    else self.isSuperAdmin = false;

    if (self._user.rol.toUpperCase() == "COUNTRYADMIN")
      self.isCountryAdmin = true;
    else self.isCountryAdmin = false;

    if (self._user.rol.toUpperCase() == "SHIPTOMANAGER")
      self.isShipToManager = true;
    else self.isShipToManager = false;

    if (!self._user.plant) self._user.plant = [];

    self._user.plant.map((e, i) => {
      self.plantList.push({
        key: "plantList" + i,
        value: e,
      });
    });
  },
  methods: {
    sendEvent() {
      (this.classHidden = "hidden"), this.$emit("close-card-user", false);
    },
    changeRole(e) {
      if (e.toUpperCase() == "SUPERADMIN") this.isSuperAdmin = true;
      else this.isSuperAdmin = false;

      if (e.toUpperCase() == "COUNTRYADMIN") this.isCountryAdmin = true;
      else this.isCountryAdmin = false;

      if (e.toUpperCase() == "SHIPTOMANAGER") this.isShipToManager = true;
      else this.isShipToManager = false;

    },

    addPlantList() {
      const self = this;
      self.plantList.push({
        key: "plantList" + new Date().getTime(),
        value: "",
      });
    },
    removePlantList(_key) {
      const self = this;
      self.plantList = self.plantList.filter((e) => e.key != _key);
    },

    actionEvent() {
      const self = this;

      // desfragmentacion de las plantlist
      let plantList = [];
      self.plantList.map((e) => {
        if (e.value != "") {
          plantList.push(e.value.toUpperCase());
        }
      });
      self._user.plant = plantList;

      if (self.btnEventText === "New") {
        if (self.formValidate()) {
          // limpiamos los campos que no corresponden a superAdmin
          if (self._user.rol.toUpperCase() == "SUPERADMIN") {
            self._user.country = "";
            self._user.plant = [];
          }
          // limpiamos los campos que no corresponden a countryAdmin
          if (self._user.rol.toUpperCase() == "COUNTRYADMIN") {
            self._user.plant = [];
          }

          if (self._user.rol.toUpperCase() == "SHIPTOMANAGER") {
            self._user.plant = [];
          }

          self.$emit("new-user", self._user);
        }
      }

      if (self.btnEventText === "Save") {
        if (self.formValidate()) {
          // limpiamos los campos que no corresponden a superAdmin
          if (self._user.rol.toUpperCase() == "SUPERADMIN") {
            self._user.country = "";
            self._user.plant = [];
          }
          // limpiamos los campos que no corresponden a countryAdmin
          if (self._user.rol.toUpperCase() == "COUNTRYADMIN") {
            self._user.plant = [];
          }

          if (self._user.rol.toUpperCase() == "SHIPTOMANAGER") {
            self._user.plant = [];
          }

          self.$emit("update-user", self._user);
        }
      }

      if (self.btnEventText === "Delete") {
        self.$emit("delete-user", self._user.email);
      }
    },

    formValidate() {
      const self = this;

      if (!self._user.email || self._user.email == "") {
        self.$emit("card-user-error", "The email is required");
        return false;
      }
      if (!self._user.rol || self._user.rol == "") {
        self.$emit("card-user-error", "The rol is required");
        return false;
      }
      if (!self._user.username || self._user.username == "") {
        self.$emit("card-user-error", "The username is required");
        return false;
      }
      // validamos si es superAdmin
      if (self._user.rol.toUpperCase() != "SUPERADMIN") {
        if (!self._user.country || self._user.country == "") {
          self.$emit("card-user-error", "The country is required");
          return false;
        }
      }
      if (self._user.rol.toUpperCase() == "PLANTMANAGER") {
        if (!self._user.plant || self._user.plant == "") {
          self.$emit("card-user-error", "The plant is required");
          return false;
        }
      }

      return true;
    },
  },
};
</script>

<style scoped>
.cardUserShadow {
  width: 100%;
  height: 100vh;
  background: #000015;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10100;
}
.cardUser {
  width: 100%;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
}
.cardUser-content {
  position: absolute;
  right: 0;
  top: 0;
  width: 90%;
  height: 100vh;
  max-width: 700px;
  background: #fff;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.25);
  z-index: 10200;
  padding-top: 53.78px;
  padding-left: 3em;
  padding-right: 3em;

  overflow-y: auto;
}
.btnClose {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.5em;
  cursor: pointer;
}

.cardUser-title {
  font-size: 20px;
  margin-bottom: 1em;
}
.cardUser-subtitle {
  width: 100%;
  font-size: 16px;
  /* margin-top: 1em;
  margin-bottom: 1em; */
  padding: 1em;
  line-height: 1.3;
}
.btn-plus {
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.btn-plus:hover {
  background: rgba(0, 0, 0, 0.05);
}

.cardUser-inputGroup {
  width: 25%;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.cardUser-sectionButtons {
  padding: 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cardUser-button {
  margin-left: 1em;
  font-size: 14px;
}
</style>