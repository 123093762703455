<template>
  <div>
    <BlockUI
      id="loading"
      :message="$t('loading')"
      v-if="loading"
      html="<i class='fa fa-cog fa-spin fa-2x fa-fw'></i>"
    />

    <CRow>
      <CCol>
        <ListUser
          v-if="!loading"
          v-bind:tableItems="listUsers"
          v-bind:countryCodes="countryCodes"
          @user-new="showcardNewUser"
          @user-edit="showcardUserEdit"
          @user-delete="showcardUserDelete"
          @driver-filter="loadDataFilter"
        />
      </CCol>
    </CRow>

    <transition name="slide-fade">
      <CardUser
        v-if="showCardUser"
        v-bind:user="userSelected"
        v-bind:title="cardTitle"
        v-bind:notification="notification"
        v-bind:notificationType="notificationType"
        v-bind:btnEventText="btnEventText"
        v-bind:btnEventType="btnEventType"
        v-bind:inputsDisabled="inputsDisabled"
        v-bind:showCollapseBottom="showCollapseBottom"
        v-bind:countryCodes="countryCodes"
        @card-user-error="showToastError"
        @update-user="updateUser"
        @delete-user="deleteUser"
        @new-user="newUser"
        @close-card-user="closeCardUser"
      />
    </transition>

    <CToaster :autohide="3000" class="main-toast">
      <CToast :key="'toast'" :show="toastShow" :color="toastColor">
        {{ toastMessage }}
      </CToast>
    </CToaster>
  </div>
</template>

<script>
import ListUser from "../components/ListUser.vue";
import CardUser from "../components/CardUser";

export default {
  name: "Users",
  components: {
    ListUser,
    CardUser,
  },
  data() {
    return {
      loading: true,

      listUsers: [],
      countryCodes: [],
      // props toast
      toastShow: false,
      toastColor: "",
      toastMessage: "",

      // props cardUser
      showCardUser: false,
      userSelected: null,
      cardTitle: null,
      notification: null,
      notificationType: null,
      btnEventText: null,
      btnEventType: null,
      inputsDisabled: null,
      showCollapseBottom: null,
    };
  },
  async created() {
    await this.loadData();
    await this.loadCountryData();
  },
  methods: {
    async loadData(filter) {
      const self = this;
      self.listUsers = [];

      try {
        const res = await self.$http.get("apiConsole/console/getUsers");
        var users = res.data.users;

        users.map((e) => {
          if (!e.country) e.country = "";
          // if (!e.plant) e.plant = "";
          if (!e.plant) e.plant = [];
        });

        if (filter) {
          if (filter.key == "PLANT" && filter.plant.trim() != "") {
            users = users.map((e) => {
              var include = false;

              e.plant.map((p) => {
                if (p.includes(filter.plant.toUpperCase())) include = true;
              });

              if (include) return e;
            });

            users = users.filter((e) => e != undefined);
          }
          if (filter.key == "COUNTRY" && filter.country != this.$t('all_countries')) {
            users = users.filter((e) => e.country == filter.country);
          }
        }

        self.listUsers = users;
      } catch (e) {
        if (e.response) {
          const res = e.response;
          console.log(res.data.message);
        }
        console.error(e);
      }
      self.loading = false;
    },
    async loadCountryData() {
      const self = this;
      self.countryCodes = [];
      
      var rol = JSON.parse(localStorage.getItem("userRol"));
      if (rol == "superadmin")
        self.countryCodes = [this.$t('all_countries')];

      try {
        const res = await self.$http.get("apiConsole/console/getCountries");
        var countries = res.data.countries;
        countries.map((e) => {
          self.countryCodes.push(e.code);
        });
      } catch (e) {
        if (e.response) {
          const res = e.response;
          console.log(res.data.message);
        }
        console.error(e);
      }
    },

    async loadDataFilter(filter) {
      await this.loadData(filter);
    },

    async updateUser(user) {
      // TODO: aqui actualizamos los datos del user contra integration
      // mostramos los errores si los hay y cerramos el modal
      const self = this;
      try {
        const res = await self.$http.put("apiConsole/console/updateUser", {
          user: user,
        });
        if (res.data) {
          await self.loadData();
          await self.loadCountryData();

          self.showToastInfo("User settings have been updated successfully");
          self.closeCardUser(false);
        }
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
      console.log(user);
    },

    async deleteUser(email) {
      const self = this;
      try {
        console.log(email);
        const res = await self.$http.put("apiConsole/console/deleteUser", {
          email: email,
        });
        if (res.data.deletedUser) {
          await self.loadData();
          await self.loadCountryData();

          self.showToastInfo("The user has been deleted successfully");
          self.closeCardUser(false);
        }
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    async newUser(user) {
      const self = this;
      try {
        const res = await self.$http.post("apiConsole/console/newUser", {
          user: user,
        });
        if (res.data.insertedUser) {
          await self.loadData();
          await self.loadCountryData();

          self.showToastInfo("The user has successfully created");
          self.closeCardUser(false);
        }
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    showcardUserEdit(cardUser) {
      const self = this;

      self.showCardUser = cardUser.showCardUser;
      self.userSelected = cardUser.userSelected;
      self.cardTitle = cardUser.cardTitle;

      self.notification = cardUser.notification;
      self.notificationType = cardUser.notificationType;

      self.btnEventText = cardUser.btnEventText;
      self.btnEventType = cardUser.btnEventType;

      self.inputsDisabled = cardUser.inputsDisabled;
      self.showCollapseBottom = true;
      self.countryCodes = cardUser.countryCodes;
    },
    showcardNewUser(cardUser) {
      const self = this;
      self.showCardUser = cardUser.showCardUser;
      self.userSelected = cardUser.userSelected;
      self.cardTitle = cardUser.cardTitle;

      self.notification = cardUser.notification;
      self.notificationType = cardUser.notificationType;

      self.btnEventText = cardUser.btnEventText;
      self.btnEventType = cardUser.btnEventType;

      self.inputsDisabled = cardUser.inputsDisabled;
      self.showCollapseBottom = true;
    },

    showcardUserDelete(cardUser) {
      const self = this;
      self.showCardUser = cardUser.showCardUser;
      self.userSelected = cardUser.userSelected;
      self.cardTitle = cardUser.cardTitle;

      self.notification = cardUser.notification;
      self.notificationType = cardUser.notificationType;

      self.btnEventText = cardUser.btnEventText;
      self.btnEventType = cardUser.btnEventType;

      self.inputsDisabled = cardUser.inputsDisabled;
      self.showCollapseBottom = true;
    },

    showToastError(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "danger";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },
    showToastInfo(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "success";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },

    async closeCardUser(toggle) {
      // TODO: reseteamos las props del cardUser
      this.showCardUser = toggle;
      this.userSelected = null;
      this.cardTitle = null;
      this.notification = null;
      this.notificationType = null;
      this.btnEventText = null;
      this.btnEventType = null;
      this.inputsDisabled = null;
      await this.loadData();
      await this.loadCountryData();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-toast {
  z-index: 11000;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>